/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DeviceStatusCompanyDto, DeviceStatusSummaryType } from "../types";

import {
  getDetailsDataValues,
  ROUTES,
  storePageFilterParamsIntoLocalStorage,
  TABLE_NAMES,
} from "../../../../../shared";
import {
  filterQueryParams,
  refreshTableState,
  takeMeThereState,
} from "../../../../../atoms/atoms";
import { FilterParamsType } from "../../../../../components/Filters/types";
import { getPageGlobalStateObjectValue } from "../../../../../components/Table/functions";
import useChangeSelectedCompany from "../../../../../components/Navbar/components/CompanySelector/hooks/useChangeSelectedCompany";
import { useAuth } from "../../../../../context/Auth/AuthProvider";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

const useDeviceStatusFunctions = () => {
  const { tokenInfo, setTokenInfo } = useAuth();
  const {
    changeSelectedCompany,
    handleCloseDesktopDetails,
    clearSeachInAllPages,
  } = useChangeSelectedCompany();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setIsRefreshTable] = useRecoilState(refreshTableState);
  const [, setIsTakeMeThere] = useRecoilState(takeMeThereState);

  const detailsDataFromTableName = (infoData: DeviceStatusSummaryType) => {
    return getDetailsDataValues(
      TABLE_NAMES.Vehicles,
      infoData.name,
      infoData.name,
      true
    );
  };

  const handleStatusInfoListItemClick = async (
    itemData: DeviceStatusCompanyDto,
    infoData: DeviceStatusSummaryType
  ) => {
    const { filterName, filterValue, pageLocation } = detailsDataFromTableName(
      infoData
    );

    const newFilterParams = {
      [filterName]: [filterValue],
    };

    storePageFilterParamsIntoLocalStorage(pageLocation, newFilterParams);

    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(pageLocation, prev, newFilterParams)
    );

    clearSeachInAllPages();

    setIsTakeMeThere(true);
    setIsRefreshTable(true);
    navigate(ROUTES.Vehicles);

    handleCloseDesktopDetails();

    await changeSelectedCompany(tokenInfo, setTokenInfo, itemData.id, false);
  };

  const renderCompaniesList = (
    infoData: DeviceStatusSummaryType,
    statusInfoListItem: CSSObject
  ) =>
    infoData.companies.map((company: DeviceStatusCompanyDto) => (
      <div
        key={company.name}
        css={css(statusInfoListItem)}
        onClick={() => handleStatusInfoListItemClick(company, infoData)}
      >
        <Typography variant="body3semiBold" sx={{ width: "40px" }}>
          {company.count}
        </Typography>
        <Tooltip
          data-testid={`device-status-company-list-item-tooltip-${company.name}`}
          title={t(`Dashboard##tooltip##take me there`)}
          placement="top"
          size="medium"
          hasArrow
        >
          <Typography variant="body3semiBold">{company.name}</Typography>
        </Tooltip>
      </div>
    ));

  const renderEmptyState = (divWithCenteredContent: CSSObject) => (
    <div css={css(divWithCenteredContent)}>
      <Typography variant="body1">{t("Dashboard##no results")}</Typography>
    </div>
  );

  return { renderCompaniesList, renderEmptyState };
};

export default useDeviceStatusFunctions;
