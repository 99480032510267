/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FindToolTopControls from "./FindToolTopControls/FindToolTopControls";
import SearchDevice from "./SearchVehicle/SearchVehicle";
import FoundResourceDetails from "./FoundResourceDetails/FoundResourceDetails";
import NotFoundResource from "./NotFoundResource/NotFoundResource";

import { FIND_VEHICLE_DTO_TYPE } from "../../../../types";

import { useDebounce } from "../../../../../../shared";
import { isValidNumberValue } from "../../../../../../shared/functions/functions";
import { ApiResources } from "../../../../../../api/resources";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";

interface FindToolContentProps {
  close: () => void;
}

const FindToolContent = ({ close }: FindToolContentProps) => {
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [
    resourceData,
    setResourceData,
  ] = useState<FIND_VEHICLE_DTO_TYPE | null>(null);
  const [notFoundErrorMsg, setNotFoundErrorMsg] = useState("");

  const debouncedValue = useDebounce(searchValue, 1000);
  const imeiLength = 15;

  const handleSearch = async () => {
    try {
      const response = await getData(
        `${ApiResources.FindVehicle}/${searchValue}`
      );
      setResourceData(response.data);
      setNotFoundErrorMsg("");
    } catch (error) {
      setResourceData(null);
      setNotFoundErrorMsg(
        (error as any).response.data.message ||
          t("Search##find tool##no vehicle found")
      );
    }
  };

  useEffect(() => {
    if (debouncedValue.length === imeiLength) {
      handleSearch();
    } else {
      debouncedValue.length === 0
        ? setError(null)
        : setError(t("Search##find tool##error not valid"));
    }
    handleClear(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.target.value;
    const isValidValue = isValidNumberValue(value);

    if (value.length <= imeiLength && isValidValue) {
      setError(null);
      setSearchValue(value);
    } else if (!isValidValue) {
      setError(t("Search##find tool##only numbers"));
    }
  };

  const handleClear = (clearSearchValue: boolean = true) => {
    clearSearchValue && setError(null);
    clearSearchValue && setSearchValue("");
    setResourceData(null);
    setNotFoundErrorMsg("");
  };

  const showNotFoundMsg = notFoundErrorMsg && !error;

  return (
    <div
      css={css({ padding: "0 16px 16px 16px" })}
      data-testid="find-tool-content"
    >
      <FindToolTopControls t={t} close={close} />

      <Typography variant="body2" sx={{ marginBottom: "12px" }}>
        {t("Search##find tool##search for vehicles by their IMEI")}
      </Typography>

      <SearchDevice
        t={t}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        clearSearchValue={handleClear}
        error={error}
      />

      {resourceData ? (
        <FoundResourceDetails
          resourceData={resourceData}
          t={t}
          onCloseDialog={close}
          searchValue={searchValue}
        />
      ) : (
        showNotFoundMsg && <NotFoundResource t={t} />
      )}
    </div>
  );
};

export default FindToolContent;
