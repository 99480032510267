export enum DETAILS_TITLES {
  Schedules = "schedules",
  CompanyCards = "company cards",
}

export enum COMPANY_DETAILS_TABS {
  Details = "details",
  FileSync = "file sync",
  EmailTransfer = "email transfer",
}

export enum DETAILS_TITLE_AREA_OPTIONS {
  DeviceImei = "device imei",
  Driver = "driver",
}
