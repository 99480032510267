import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import useChangeCompanyFunctions from "./useChangeCompanyFunctions";

import { generateErrorMessageToDisplay } from "../functions";

import { MIN_CHAR_LENGTH_IN_SEARCH } from "../../../../Search/constants";
import { deselectAllRows } from "../../../../Table/functions";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../api/resources";
import {
  companyListForCompanySelector,
  currPage,
  filterQueryParams,
  noResultClear,
  pagesDefaultValue,
  resetDetailsSectionOnPageChange,
  searchQueryParams,
  selectedTableRows,
  tableDetails,
  tableDetailsSectionState,
  tableLoading,
  tokenRefreshState,
} from "../../../../../atoms/atoms";
import { ALERT_STATUS } from "../../../../../context/alert/types";
import { useAuth } from "../../../../../context/Auth/AuthProvider";
import {
  ALL_ROUTES,
  CompaniesDto,
  getSearchQueryParamsFromLocalStorage,
  LOCAL_STORAGE_KEYS,
  updatePageParamsStateInLocalStorage,
} from "../../../../../shared";
import { setTokensToLocalStorage } from "../../../../../shared/functions/auth";
import useRefreshTableData from "../../../../../shared/hooks/useRefreshTableData";
import useResponse from "../../../../../shared/hooks/useResponse";

const useChangeSelectedCompany = () => {
  const { tokenInfo } = useAuth();
  const { handleResponse } = useResponse();
  const { postData, getData } = useAxiosPrivate();
  const { refreshTableData } = useRefreshTableData();
  const { refreshPageData } = useChangeCompanyFunctions();
  const location = useLocation();
  const { t } = useTranslation();

  const [, setPageToShow] = useRecoilState(currPage);
  const [isTokenRefreshLoading, setIsTokenRefreshLoading] = useRecoilState(
    tokenRefreshState
  );
  const [, setIsTableLoading] = useRecoilState(tableLoading);
  const [, setSelectedRows] = useRecoilState<any>(selectedTableRows);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setIsDetailsResetNeeded] = useRecoilState(
    resetDetailsSectionOnPageChange
  );
  const [, setCompanyList] = useRecoilState(companyListForCompanySelector);
  const [, setSearchParams] = useRecoilState(searchQueryParams);
  const [, setNoResultClearCall] = useRecoilState(noResultClear);
  const [, setFilterParams] = useRecoilState(filterQueryParams);

  const [isRefreshTableEnabled, setIsRefreshTableEnabled] = useState<boolean>(
    false
  );

  const resource = ApiResources.ChangeSelectedCompany;

  const queryWithSearch =
    getSearchQueryParamsFromLocalStorage(location.pathname)?.length >=
      MIN_CHAR_LENGTH_IN_SEARCH &&
    getSearchQueryParamsFromLocalStorage(location.pathname);

  const searchQuery = queryWithSearch || null;

  useEffect(() => {
    if (isRefreshTableEnabled) {
      // refreshTable() call moved to useChangeCompanyFunctions hook to reduce code complexity
      refreshPageData(refreshTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfo, isRefreshTableEnabled, location]);

  const refreshTable = () => {
    callApiToChangeCompany();

    // reset page number per page to default values
    setPageToShow(pagesDefaultValue("1"));

    updatePageParamsStateInLocalStorage(
      ALL_ROUTES.AllRoutes,
      LOCAL_STORAGE_KEYS.PageNumber,
      "1"
    );

    // deselect all table rows
    deselectAllRows(setSelectedRows);
  };

  const callApiToChangeCompany = () => {
    searchQuery && refreshTableData(false, searchQuery, true);
    !searchQuery && refreshTableData(undefined, undefined, true);
  };

  const setTokensAndEnableRefresh = (
    response: AxiosResponse<any, any>,
    setTokenInfo: any,
    shouldRefreshTable: boolean = true
  ) => {
    setTokenInfo({
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    });
    setTokensToLocalStorage({
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    });

    shouldRefreshTable && setIsRefreshTableEnabled(true);
  };

  const changeSelectedCompany = async (
    tokenInfo: { token: string; refreshToken: string },
    setTokenInfo: any,
    selectedCompany: CompaniesDto | string,
    shouldRefreshTable?: boolean
  ) => {
    const payload = {
      accessToken: tokenInfo.token,
      refreshToken: tokenInfo.refreshToken,
      companyId: selectedCompany,
    };
    if (!isTokenRefreshLoading) {
      setIsTokenRefreshLoading(true);
      setIsTableLoading(true);

      try {
        const response = await postData(resource, payload);

        response &&
          setTokensAndEnableRefresh(response, setTokenInfo, shouldRefreshTable);
      } catch (error) {
        const err = error as AxiosError<any>;

        handleResponse(
          ALERT_STATUS.Critical,
          generateErrorMessageToDisplay(err, t)
        );

        // reset loading state in case of error
        setIsTableLoading(false);
      } finally {
        setNoResultClearCall(false); // reset noResultClearCall to false so that search would work
        setIsTokenRefreshLoading(false);
        // table loading state turns into false in refreshTable() function call
      }
    }
  };

  // close details section in all the pages
  const handleCloseDesktopDetails = () => {
    setIsDetailsOpen(false);
    setTableDetails({
      vehiclesPage: null,
      driversPage: null,
      companiesPage: null,
    });

    setIsDetailsResetNeeded(false);
    updatePageParamsStateInLocalStorage(
      ALL_ROUTES.AllRoutes,
      LOCAL_STORAGE_KEYS.SelectedTableItem,
      null
    );

    updatePageParamsStateInLocalStorage(
      ALL_ROUTES.AllRoutes,
      LOCAL_STORAGE_KEYS.DesktopDetailsSection,
      false
    );
  };

  const getCompanyList = async () => {
    try {
      const response = await getData(ApiResources.CompanyList);
      response && setCompanyList(response.data?.companies);
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        handleResponse(ALERT_STATUS.Critical, err.message);
      }
    }
  };

  const clearSeachInAllPages = (fromCompanyList?: boolean) => {
    // remove search in all the pages
    setSearchParams(pagesDefaultValue(null));

    updatePageParamsStateInLocalStorage(
      ALL_ROUTES.AllRoutes,
      LOCAL_STORAGE_KEYS.Contains,
      null
    );

    // clear search field
    fromCompanyList && setNoResultClearCall(true);
  };

  const clearFiltersInAllPages = () => {
    // remove filters in all the pages
    setFilterParams(pagesDefaultValue(null));

    updatePageParamsStateInLocalStorage(
      ALL_ROUTES.AllRoutes,
      LOCAL_STORAGE_KEYS.Filters,
      null
    );
  };

  return {
    changeSelectedCompany,
    handleCloseDesktopDetails,
    getCompanyList,
    clearSeachInAllPages,
    clearFiltersInAllPages,
  };
};

export default useChangeSelectedCompany;
