import { Location } from "react-router";

import { DETAILS_TITLE_AREA_OPTIONS, DetailsDto } from "./types";

import { ROUTES } from "../../shared";

export const renderTitle = (
  // type of companies details is not known yet
  detailsData: DetailsDto | any,
  location: Location
) => {
  if (detailsData) {
    switch (location.pathname) {
      case ROUTES.Vehicles:
        return {
          title: DETAILS_TITLE_AREA_OPTIONS.DeviceImei,
          value: detailsData.imei,
          copy: true,
        };
      case ROUTES.Drivers:
        return {
          title: DETAILS_TITLE_AREA_OPTIONS.Driver,
          value: detailsData.cardName,
          copy: false,
        };
      case ROUTES.Companies:
        return { title: null, value: detailsData.name, copy: false };

      default:
        return {};
    }
  }
};
