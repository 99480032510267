import { useContext } from "react";
import { Typography } from "@mui/material";

import { useMediaQueries } from "../../../../../shared";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

interface IDetailsCardItemTitleProps {
  title: string;
  customLineHeight?: string;
  showColon?: boolean;
}

const DetailsCardItemTitle = ({
  title,
  customLineHeight,
  showColon = true,
}: IDetailsCardItemTitleProps) => {
  const { colors } = useContext(ThemeContext);
  const { toMd, toLg } = useMediaQueries();

  const titleLineHeight = customLineHeight ?? "inherit";

  const PAPER_CARD_ITEM_TITLE_STYLE = toMd
    ? {
        width: "156px",
      }
    : {
        minWidth: "112px",
        maxWidth: "112px",
        marginRight: toLg ? "2px" : "16px", // 2px is used to avoid wrapped numeric values
      };

  return (
    <Typography
      sx={[
        PAPER_CARD_ITEM_TITLE_STYLE,
        { lineHeight: titleLineHeight, color: colors.textSecondary },
      ]}
      variant="body2"
    >
      {`${title}${showColon ? ":" : ""}`}
    </Typography>
  );
};

export default DetailsCardItemTitle;
