import PageHeaderActions from "../PageHeaderActions";
import { useDialog } from "../../hooks/useDialog";
import DownloadDialog from "../../dialogs/reusableDialogs/DownloadDialog/DownloadDialog";
import FileSettingsDialog from "../../dialogs/reusableDialogs/FileSettingsDialog/FileSettingsDialog";
import {
  ROUTES,
  TABLE_NAMES,
  VEHICLE_FILES_ACTIONS,
} from "../../../../../shared";
import { useReleaseFeatureToggle } from "../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../shared/featureToggles/types";

const VehicleFilesHeaderActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const filesTableName = TABLE_NAMES.Vehicles;
  const showVehicleFileSettings = isReleaseFeatureEnabled(
    FEATURE_NAMES.ShowFileSettingsButton
  );

  return (
    <>
      <PageHeaderActions
        route={ROUTES.VehicleFiles}
        openDialog={openDialog}
        download
        fileSettings={showVehicleFileSettings}
      >
        <>
          <DownloadDialog
            isOpen={isDialogOpen[VEHICLE_FILES_ACTIONS.Download]}
            onClose={closeDialog}
            filesTableName={filesTableName}
          />
          <FileSettingsDialog
            isOpen={isDialogOpen[VEHICLE_FILES_ACTIONS.FileSettings]}
            onClose={closeDialog}
            filesTableName={filesTableName}
          />
        </>
      </PageHeaderActions>
    </>
  );
};

export default VehicleFilesHeaderActions;
