export enum FEATURE_NAMES {
  AllowedToCreateNewClientCompany = "allowedToCreateNewClientCompany",
  AllowedToChangeRootCompany = "allowedToChangeRootCompany",
  AllowedToInviteAllRoles = "allowedToInviteAllRoles",
  AllowedFullControlOfUsers = "allowedFullControlOfUsers",
  CancelInvitation = "cancelInvitation",
  ChangeLanguage = "changeLanguage",
  ChangePassword = "changePassword",
  ShowSecuritySettings = "showSecuritySettings",
  CcaFileDownload = "ccaFileDownload",
  ResendInvitation = "resendInvitation",
  ShowCompanySelector = "showCompanySelector",
  ShowFileSettingsButton = "showFileSettingsButton",
  ShowFindTool = "showFindTool",
  ShowNotifications = "showNotifications",
  ShowInviteUser = "showInviteUser",
  ShowToggle2FA = "showToggle2FA",
  ShowTutorials = "showTutorials",
  ShowDriversLastActivityFilter = "showDriversLastActivityFilter",
  ShowHistoryButton = "showHistoryButton",
  ShowDeleteCompanyButton = "showDeleteCompanyButton",
  ShowAdditionalNavigationButtons = "showAdditionalNavigationButtons",
  ManageNews = "manageNews",
  PublishNews = "publishNews",
}
