import PageHeaderActions from "../PageHeaderActions";
import DownloadDialog from "../../dialogs/reusableDialogs/DownloadDialog/DownloadDialog";
import FileSettingsDialog from "../../dialogs/reusableDialogs/FileSettingsDialog/FileSettingsDialog";
import { useDialog } from "../../hooks/useDialog";
import {
  DRIVER_FILES_ACTIONS,
  ROUTES,
  TABLE_NAMES,
} from "../../../../../shared";
import { useReleaseFeatureToggle } from "../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../shared/featureToggles/types";

const DriversFilesPageHeaderActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const filesTableName = TABLE_NAMES.Drivers;
  const showDriverFileSettings = isReleaseFeatureEnabled(
    FEATURE_NAMES.ShowFileSettingsButton
  );

  return (
    <>
      <PageHeaderActions
        route={ROUTES.DriverFiles}
        openDialog={openDialog}
        download
        fileSettings={showDriverFileSettings}
      >
        <>
          <DownloadDialog
            isOpen={isDialogOpen[DRIVER_FILES_ACTIONS.Download]}
            onClose={closeDialog}
            filesTableName={filesTableName}
          />
          <FileSettingsDialog
            isOpen={isDialogOpen[DRIVER_FILES_ACTIONS.FileSettings]}
            onClose={closeDialog}
            filesTableName={filesTableName}
          />
        </>
      </PageHeaderActions>
    </>
  );
};

export default DriversFilesPageHeaderActions;
