/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import useChangeSelectedCompany from "../../../../CompanySelector/hooks/useChangeSelectedCompany";

import { FIND_VEHICLE_DTO_TYPE } from "../../../../../types";

import TextLink from "../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../TextLink/types";
import { getPageGlobalStateObjectValue } from "../../../../../../Table/functions";
import useTutorialMode from "../../../../../../TutorialMode/hooks/useTutorialMode";

import {
  isStoredPageParams,
  LOCAL_STORAGE_KEYS,
  removeFiltersFromSpecificPage,
  ROUTES,
  setPageParamsToLocalStorage,
  updateQueryParamsInLocalStorage,
} from "../../../../../../../shared";
import {
  filterQueryParams,
  findToolSearch,
  refreshTableState,
  searchQueryParams,
  takeMeThereState,
} from "../../../../../../../atoms/atoms";
import { SearchQueryParamsStateType } from "../../../../../../../atoms/types";
import { useAuth } from "../../../../../../../context/Auth/AuthProvider";

interface FoundResourceDetailsProps {
  resourceData: FIND_VEHICLE_DTO_TYPE;
  t: TFunction<"translation", undefined>;
  onCloseDialog: () => void;
  searchValue: string;
}

const FoundResourceDetails = ({
  resourceData,
  t,
  onCloseDialog,
  searchValue,
}: FoundResourceDetailsProps) => {
  const { tokenInfo, setTokenInfo, user } = useAuth();
  const navigate = useNavigate();
  const { handleCloseDetailsCompletely } = useTutorialMode();
  const {
    changeSelectedCompany,
    handleCloseDesktopDetails,
    getCompanyList,
    clearSeachInAllPages,
    clearFiltersInAllPages,
  } = useChangeSelectedCompany();

  const [, setSearchParams] = useRecoilState(searchQueryParams);
  const [, setIsFindToolSearch] = useRecoilState(findToolSearch);
  const [, setIsTakeMeThere] = useRecoilState(takeMeThereState);
  const [, setIsRefreshTable] = useRecoilState(refreshTableState);
  const [, setFilterParams] = useRecoilState(filterQueryParams);

  const generateDetailsSingleRow = (title: string, value: string) => (
    <div css={css({ display: "flex", marginBottom: "8px" })}>
      <Typography variant="body2" sx={{ width: "136px" }}>
        {title}:
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );

  const handleGoToVehicles = async () => {
    const pageLocation = ROUTES.Vehicles;

    const hasToChangeCompany =
      user?.selectedCompany?.id !== resourceData.companyId;

    hasToChangeCompany && setIsTakeMeThere(true);
    hasToChangeCompany && setIsRefreshTable(true); // prevents from unnecessary api call before data change

    // clean search field in all pages
    hasToChangeCompany && clearSeachInAllPages();

    // then if page params are not stored in local storage, store them
    if (!isStoredPageParams(pageLocation)) {
      setPageParamsToLocalStorage({
        pathname: pageLocation,
        currentPage: "1",
        rowsPerPage: "25",
        selectedTableItem: null,
        searchQueryParams: searchValue,
        desktopDetailsSection: undefined,
        filters: null,
      });
    } else {
      updateQueryParamsInLocalStorage(
        pageLocation,
        LOCAL_STORAGE_KEYS.Contains,
        searchValue
      );
    }

    // set search params in page due to page location
    setSearchParams((prev: SearchQueryParamsStateType) =>
      getPageGlobalStateObjectValue(pageLocation, prev, searchValue)
    );

    // turn on find tool search (it's needed to show search value in search field)
    setIsFindToolSearch(true);

    // remove all filters
    hasToChangeCompany
      ? clearFiltersInAllPages()
      : removeFiltersFromSpecificPage(setFilterParams, pageLocation);

    navigate(pageLocation);

    // // close details and dialog
    handleCloseDetailsCompletely(pageLocation);
    onCloseDialog();

    handleCloseDesktopDetails();

    hasToChangeCompany &&
      (await changeSelectedCompany(
        tokenInfo,
        setTokenInfo,
        resourceData.companyId,
        false
      ));

    hasToChangeCompany && getCompanyList(); //update company list after selected company change
  };

  return (
    <div css={css({ marginTop: "14px" })} data-testid="found-resource-details">
      {generateDetailsSingleRow(
        t("Dialog##vehicle number"),
        resourceData.vehicleNumber
      )}
      {generateDetailsSingleRow(t("Dialog##company"), resourceData.companyName)}
      {generateDetailsSingleRow(
        t("Dialog##client company"),
        resourceData.clientCompanyName
      )}
      <TextLink
        size={TEXT_LINK_SIZES.Normal}
        onClick={handleGoToVehicles}
        customStyle={{ textDecoration: "none" }}
      >
        {t("Actions##go to vehicle")}
      </TextLink>
    </div>
  );
};

export default FoundResourceDetails;
