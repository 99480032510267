import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import useTableDataFunctions from "./useTableDataFunctions";

import {
  getValuesFromObjectArray,
  pageGlobalStateObjectResult,
} from "../functions";

import { MIN_CHAR_LENGTH_IN_SEARCH } from "../../Search/constants";
import useTutorialMode from "../../TutorialMode/hooks/useTutorialMode";

import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import {
  currPage,
  filterQueryParams,
  filterRefreshTable,
  findToolSearch,
  initialLoading,
  noSearchResults,
  refreshTableState,
  resetDetailsSectionOnPageChange,
  searchQueryParams,
  tableData,
  tableDetailsSectionState,
  tableLoading,
  tablePageData,
  tableRowsPerPage,
  tableSortedColumn,
  takeMeThereState,
} from "../../../atoms/atoms";
import { ALERT_STATUS } from "../../../context/alert/types";
import useResponse from "../../../shared/hooks/useResponse";
import { ROUTES } from "../../../shared";

const useTableData = (tableRowDataMap?: any, preventDataFetch?: boolean) => {
  const { axiosPrivate, getData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const {
    setTableRowData,
    routeWithoutSearch,
    setPageDataState,
    getFiltersAndFormFilterQueryParams,
  } = useTableDataFunctions();
  const location = useLocation();
  const {
    handleTutorialInCompaniesTable,
    handleTutorialInVehiclesTable,
  } = useTutorialMode();

  const pageToShow = useRecoilValue(currPage);
  const rowsPerPage = useRecoilValue(tableRowsPerPage);
  const searchParams = useRecoilValue(searchQueryParams);
  const filterParams = useRecoilValue(filterQueryParams);
  const isRefreshTable = useRecoilValue(refreshTableState);
  const [refreshFromFilter, setRefreshFromFilter] = useRecoilState(
    filterRefreshTable
  );

  const sortedColumn = useRecoilValue(tableSortedColumn);

  const [, setIsLoading] = useRecoilState(tableLoading);
  const [, setPageData] = useRecoilState<any>(tablePageData);
  const [, setTableData] = useRecoilState(tableData);
  const [, setNoResults] = useRecoilState(noSearchResults);
  const [, setIsDetailsResetNeeded] = useRecoilState(
    resetDetailsSectionOnPageChange
  );
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setIsInitialLoading] = useRecoilState(initialLoading);
  const isTakeMeThere = useRecoilValue(takeMeThereState);
  const isFindToolSearch = useRecoilValue(findToolSearch);

  const initialized = useRef(false);
  const fetchedDataRef: any = useRef(null);
  const currentPage = pageGlobalStateObjectResult(
    location.pathname,
    pageToShow
  );

  // this check is needed to make sure that api is not called with page number 0 (what causes error from backend)
  const firstPage = currentPage === "0" && "1";
  const pageParam = firstPage || currentPage;

  const rowsCount = pageGlobalStateObjectResult(location.pathname, rowsPerPage);
  const companiesRoute = location.pathname === ROUTES.Companies;
  const vehiclesRoute = location.pathname === ROUTES.Vehicles;

  const memoizedSortedColumnValues = useMemo(
    () => getValuesFromObjectArray(sortedColumn),
    [sortedColumn]
  );
  const memoizedFilterParamsValues = useMemo(
    () => getValuesFromObjectArray(filterParams),
    [filterParams]
  );

  const getApiQueryParams = () => {
    if (
      location.pathname === ROUTES.Dashboard ||
      location.pathname === ROUTES.Home
    ) {
      return location.pathname;
    }

    const sortingInfo = pageGlobalStateObjectResult(
      location.pathname,
      sortedColumn
    );

    const sortingString = !sortingInfo?.orderBy
      ? ""
      : `OrderBy=${sortingInfo?.orderBy}&Descending=${sortingInfo?.descending}&`;

    const pageNumberString = pageParam ? `PageNumber=${pageParam}&` : "";

    const queryWithSearchParams = `${
      location.pathname
    }?${sortingString}${pageNumberString}PageSize=${rowsCount?.toString()}&Contains=${pageGlobalStateObjectResult(
      location.pathname,
      searchParams
    )}`;

    const queryWithoutSearchParams = `${
      location.pathname
    }?${sortingString}${pageNumberString}PageSize=${rowsCount?.toString()}`;

    const hasSearchParams =
      pageGlobalStateObjectResult(location.pathname, searchParams) &&
      pageGlobalStateObjectResult(location.pathname, searchParams).length >=
        MIN_CHAR_LENGTH_IN_SEARCH;

    return hasSearchParams
      ? queryWithSearchParams +
          getFiltersAndFormFilterQueryParams(location, filterParams)
      : queryWithoutSearchParams +
          getFiltersAndFormFilterQueryParams(location, filterParams);
  };

  useEffect(() => {
    const isReadyToCallApi =
      !preventDataFetch &&
      !initialized.current &&
      currentPage &&
      rowsCount &&
      !isRefreshTable &&
      refreshFromFilter &&
      !isFindToolSearch;

    if (isReadyToCallApi) {
      initialized.current = true;
      setIsLoading(true);

      const apiQueryParams = getApiQueryParams();

      const getTableData = async () => {
        await getData(
          routeWithoutSearch(companiesRoute, location) || apiQueryParams
        )
          .then((response: any) => {
            fetchedDataRef.current = response.data;

            setPageDataState(
              response,
              companiesRoute,
              setPageData,
              tableRowDataMap
            );

            setTableRowData(
              response,
              companiesRoute,
              tableRowDataMap,
              setTableData
            );

            const responseItems = response?.data.items;

            const noSearchResultsFound =
              pageGlobalStateObjectResult(location.pathname, searchParams) &&
              responseItems?.length === 0;

            const noFiltersResultFound =
              pageGlobalStateObjectResult(location.pathname, filterParams) &&
              responseItems?.length === 0;

            setNoResults(noSearchResultsFound || noFiltersResultFound);
            return responseItems;
          })
          // .then chain is needed here to make sure that the state actions are made one by one.
          // Otherwise actions sequence is random.
          .catch((error) => {
            // error page handling logic should be handled in the future

            handleResponse(ALERT_STATUS.Critical, error.message);
          })
          .then((responseItems: any) => {
            setIsLoading(false);
            setIsInitialLoading(false);
            //  the setIsDetailsResetNeeded() below resets table to render loading spinner instead of table loader, after routing to other page
            setIsDetailsResetNeeded(false);

            companiesRoute && handleTutorialInCompaniesTable(setIsDetailsOpen);
            vehiclesRoute &&
              handleTutorialInVehiclesTable(responseItems?.length);
          });

        initialized.current = false;
      };

      getTableData();
    }

    if (!refreshFromFilter) {
      setRefreshFromFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    axiosPrivate,
    currentPage,
    rowsPerPage,
    location.pathname,
    memoizedSortedColumnValues,
    memoizedFilterParamsValues,
    refreshTableState,
    isTakeMeThere,
  ]);

  const fetchedData = fetchedDataRef.current;

  return { fetchedData, getApiQueryParams };
};

export default useTableData;
