import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import { ButtonSizes } from "../Button/types";
import CopyIcon from "../../assets/customIcons/actions/CopyIcon";

interface CopyButtonProps {
  size: ButtonSizes;
  copiedValue: string;
  buttonCustomStyle?: CSSProperties;
  testId?: string;
}

const CopyButton = ({
  size,
  copiedValue,
  buttonCustomStyle,
  testId,
}: CopyButtonProps) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard text={copiedValue} onCopy={() => setCopied(true)}>
      <Tooltip
        title={copied ? t("Button##copied") : t("Button##copy to clipboard")}
        size="medium"
        placement="top"
        TransitionProps={{
          onExited: () => setCopied(false),
        }}
      >
        <Button
          data-testid={testId || "copy-button"}
          style={buttonCustomStyle}
          variant="iconOnly"
          size={size}
          color="secondary"
          icon={<CopyIcon isLarge={size === "tiny"} />}
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyButton;
