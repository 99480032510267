/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import { useStyles } from "../../../styles";

import CopyButton from "../../../../CopyButton/CopyButton";

import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

interface IDetailsCardItemTextProps {
  textToRender: string | number | null | undefined;
  copiedValue?: string;
  hasCopyButton?: boolean;
  customStyle?: {};
  titleInlineAddition?: string | number | null;
}

const DetailsCardItemText = ({
  textToRender,
  hasCopyButton,
  copiedValue,
  customStyle,
  titleInlineAddition,
}: IDetailsCardItemTextProps) => {
  const { colors } = useContext(ThemeContext);

  const { copyButtonCustomStyle } = useStyles();

  const displayText = () => {
    if (!textToRender) {
      return typeof textToRender === "number" ? textToRender : "-";
    }
    return textToRender;
  };

  return (
    <>
      <Typography
        variant="body2"
        sx={customStyle}
        data-testid="details-card-item-text"
      >
        {displayText()}
        {titleInlineAddition && (
          <span css={css({ color: colors.textPlaceholder, marginLeft: "4px" })}>
            {titleInlineAddition}
          </span>
        )}
      </Typography>
      {hasCopyButton && copiedValue && (
        <CopyButton
          size="normal"
          copiedValue={copiedValue}
          buttonCustomStyle={copyButtonCustomStyle as {}}
        />
      )}
    </>
  );
};

export default DetailsCardItemText;
