/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { Location } from "react-router";
import useResizeObserver from "use-resize-observer";

import {
  MARGIN_LEFT_AND_RIGHT,
  MAX_DESCRIPTION_LENGTH,
} from "../../../constants";
import { renderTitle } from "../../../functions";
import { DetailsDto } from "../../../types";
import { useStyles } from "../../../styles";

import Button from "../../../../Button/Button";
import Tooltip from "../../../../Tooltip/Tooltip";
import TextLink from "../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../TextLink/types";
import { LongTextTooltip } from "../../../../Tooltip/LongTextTooltip";
import CopyButton from "../../../../CopyButton/CopyButton";

import { useMediaQueries } from "../../../../../shared";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";
import { useOverflow } from "../../../../../shared/hooks/useOverflow";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

interface IDetailsTitleAreaProps {
  displayLoader: boolean;
  detailsData: DetailsDto;
  onClose: (() => void) | undefined;
  location: Location;
  setTitleAreaHeight: Dispatch<SetStateAction<number | null>>;
}

const DetailsTitleArea = ({
  displayLoader,
  detailsData,
  onClose,
  location,
  setTitleAreaHeight,
}: IDetailsTitleAreaProps) => {
  const { colors } = useContext(ThemeContext);

  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();
  const { measuredRef, isOverflowing } = useOverflow();

  const { ref, height = 1 } = useResizeObserver();

  const [showMoreText, setShowMoreText] = useState<boolean>(false);

  const {
    detailsTitle,
    detailsTitleArea,
    detailsTitleAreaWithLoader,
    closeDetailsButton,
    copyButtonCustomStyle,
    detailsTitleWithInlineAction,
  } = useStyles();

  useLayoutEffect(() => {
    setTitleAreaHeight(height + 46); // 46 is total number of padding top and bottom
  }, [showMoreText, height, setTitleAreaHeight, isOverflowing]);

  const showCloseButton = (fromMd && detailsData) || (fromMd && displayLoader);
  const hasCopyButton = renderTitle(detailsData, location)?.copy;
  const titleText = t(`Details##${renderTitle(detailsData, location)?.title}`);
  const titleValueFromFetchedData = renderTitle(detailsData, location)?.value;
  const marginAfterDescriptionSpan = 8;

  const handleToggleShowMore = () => {
    setShowMoreText(!showMoreText);
  };

  const titleSpan = (textLine: string, margin: number) => (
    <span
      css={css({ color: colors.textPlaceholder, marginRight: `${margin}px` })}
    >
      {textLine}:
    </span>
  );

  const showMore = (textToShow: any) => {
    const isEmpty = !textToShow || textToShow === "";
    const marginAfterTitleSpan = 4;

    if (textToShow?.length > MAX_DESCRIPTION_LENGTH) {
      return (
        <>
          <Typography variant="body2" sx={{ overflowWrap: "break-word" }}>
            {titleSpan(t("General##Description"), marginAfterTitleSpan)}
            {!showMoreText
              ? `${textToShow.slice(0, MAX_DESCRIPTION_LENGTH)}...`
              : textToShow}{" "}
            <span>
              <TextLink
                size={TEXT_LINK_SIZES.Normal}
                onClick={handleToggleShowMore}
                sx={{ overflowWrap: "break-word" }}
              >
                {!showMoreText
                  ? t("Details##show more")
                  : t("Details##show less")}
              </TextLink>
            </span>
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="body2">
        {!isEmpty && titleSpan(t("General##Description"), marginAfterTitleSpan)}
        {textToShow}
      </Typography>
    );
  };

  return (
    <div
      ref={ref}
      data-testid="details-title-area"
      css={css([
        {
          width: `calc(100% - ${MARGIN_LEFT_AND_RIGHT})`,
          height: displayLoader ? "102px" : "auto",
        },
        detailsTitleArea,
      ])}
    >
      <div css={css(detailsTitleAreaWithLoader)}>
        {displayLoader ? (
          <RectangleLoader
            height="24"
            width="216px"
            testId="details-data-number-loader"
          />
        ) : (
          detailsData &&
          titleValueFromFetchedData && (
            <LongTextTooltip
              title={titleValueFromFetchedData}
              open={isOverflowing}
            >
              <Typography
                ref={measuredRef}
                data-testid="details-title"
                variant="h6"
                css={css([
                  detailsTitle,
                  hasCopyButton && detailsTitleWithInlineAction,
                ])}
              >
                {renderTitle(detailsData, location)?.title &&
                  titleSpan(titleText, marginAfterDescriptionSpan)}
                {titleValueFromFetchedData}

                {hasCopyButton && (
                  <span>
                    <CopyButton
                      testId="title-copy-button"
                      size="normal"
                      copiedValue={titleValueFromFetchedData}
                      buttonCustomStyle={copyButtonCustomStyle as {}}
                    />
                  </span>
                )}
              </Typography>
            </LongTextTooltip>
          )
        )}

        {showCloseButton && (
          <Tooltip
            data-testid="close-details-tooltip"
            title={t("Details##close details")}
            placement="top"
            size="medium"
            hasArrow
          >
            <Button
              variant="iconOnly"
              size="small"
              color="white"
              icon={<CloseIcon />}
              onClick={onClose}
              css={css(closeDetailsButton)}
            />
          </Tooltip>
        )}
      </div>

      <div>
        {displayLoader ? (
          <RectangleLoader
            height="20"
            width={fromMd ? "100%" : "380px"}
            customStyle={{ marginTop: fromMd ? "unset" : "8px" }}
          />
        ) : (
          detailsData && showMore(detailsData.comment)
        )}
      </div>
    </div>
  );
};

export default DetailsTitleArea;
