import { RadioGroup, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import useChangeSelectedCompany from "../../../../hooks/useChangeSelectedCompany";

import { useStyles } from "../../../../../../styles";

import RadioListItem from "../../../../../../../RadioListItem/RadioListItem";

import {
  filterQueryParams,
  pagesDefaultValue,
  refreshTableState,
  tokenRefreshState,
} from "../../../../../../../../atoms/atoms";
import { useAuth } from "../../../../../../../../context/Auth/AuthProvider";
import { CompaniesDto, useMediaQueries } from "../../../../../../../../shared";

interface ICompanyListProps {
  searchValue: string;
  companies: CompaniesDto[];
}

const CompanyList = ({ searchValue, companies }: ICompanyListProps) => {
  const { user, tokenInfo, setTokenInfo } = useAuth();

  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();
  const {
    changeSelectedCompany,
    handleCloseDesktopDetails,
    clearSeachInAllPages,
  } = useChangeSelectedCompany();

  const {
    childCompaniesCount,
    selectCompanyRadioGroupCSS,
    noCompaniesFoundCSS,
  } = useStyles(fromMd);

  const isTokenRefreshLoading = useRecoilValue(tokenRefreshState);

  const [, setIsRefreshTable] = useRecoilState(refreshTableState);
  const [, setFilterParams] = useRecoilState(filterQueryParams);

  const [selectedCompany, setSelectedCompany] = useState<CompaniesDto | string>(
    user.selectedCompany.id
  );

  const filteredCompanyListBySearchValue = companies.filter(
    (company: CompaniesDto) =>
      company.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  // remove filters in all the pages
  const handleRemoveFilters = () => {
    setFilterParams(pagesDefaultValue(null));
  };

  const handleChangeSelection = async (value: any) => {
    if (!isTokenRefreshLoading) {
      setIsRefreshTable(true);
      handleCloseDesktopDetails();

      handleRemoveFilters();

      clearSeachInAllPages(true);

      setSelectedCompany(value);

      await changeSelectedCompany(tokenInfo, setTokenInfo, value, true);
      // setIsRefreshTable(false) is moved to CompanySelector into close dialog event
    }
  };

  return (
    <>
      {searchValue.length > 0 &&
      filteredCompanyListBySearchValue.length === 0 ? (
        <Typography variant="body2" sx={noCompaniesFoundCSS as SxProps<Theme>}>
          {t("Table##empty table##no results found##title")}
        </Typography>
      ) : (
        <RadioGroup
          data-testid="select-company-radio-group"
          value={selectedCompany}
          onChange={(_, value) => handleChangeSelection(value)}
          sx={selectCompanyRadioGroupCSS as SxProps<Theme>}
        >
          <Typography
            variant="subtitle1semiBold"
            sx={childCompaniesCount as SxProps<Theme>}
          >
            {t("Selector##companies", { companyCount: companies.length })}
          </Typography>

          {filteredCompanyListBySearchValue.map((company: CompaniesDto) => (
            <RadioListItem
              data-testid="company-list-item"
              key={company.id}
              value={company.id}
              label={company.name}
              selectedValue={selectedCompany}
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default CompanyList;
